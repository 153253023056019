<template>
  <div>
    <img
      :src="img"
      style="width: 100%"
      @click="$router.push('/e-learning-content')"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/assets/image/elearning-list-content.png"),
    };
  },
};
</script>
